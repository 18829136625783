import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "./global.service";

@Injectable({
  providedIn: 'root'
})
export class TipOfDayService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,) { }

  getTipofTheDay() {
    return this.http.get(this.globalService.getAPIUrl() + 'notification/get-tip-of-the-day', this.globalService.getHttpOptions());
  }
}
