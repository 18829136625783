import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalService } from "../../global.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NotificationService } from "../../notification.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-tip-of-the-day',
  templateUrl: './tip-of-the-day.component.html',
  styleUrls: ['./tip-of-the-day.component.scss']
})
export class TipOfTheDayComponent implements OnInit, OnDestroy {
  rentalModalRef?: BsModalRef;
  loadingModalRef?: BsModalRef;

  @ViewChild('tipOfTheDayModal') tipOfTheDayModal?: TemplateRef<any>;
  @ViewChild('tipOfTheDayLoadingModal') tipOfTheDayLoadingModal?: TemplateRef<any>;

  properties: any = [];
  tipSubscription: Subscription; // Track the subscription
  tipOfTheDayType = '';
  constructor(
    private globalService: GlobalService,
    private modalService: BsModalService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.tipSubscription = this.globalService.tipOfTheDayEvent.subscribe(data => {
      if (data !== null) {
        // Close the loading modal if open
        // if (this.loadingModalRef) {
        //  this.loadingModalRef.hide();
        // this.loadingModalRef = undefined;  // Reset the reference
        // }
        this.tipOfTheDayType = data.type;
        this.properties = data.data;
        data.data != null ? this.openRentalModal():'';
      } else {
        // Show the loading modal if no data is found
        // this.openLoadingModal();
      }
    });

  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent multiple instances on logout/login
    if (this.tipSubscription) {
      this.tipSubscription.unsubscribe();
    }
  }

  private openRentalModal(size= 'modal-lg'): void {
    this.rentalModalRef = this.modalService.show(this.tipOfTheDayModal, {
      backdrop: 'static',
      ignoreBackdropClick: true,
      class: size
    });
  }

  private openLoadingModal(): void {
    if (!this.loadingModalRef) {  // Avoid opening multiple loading modals
      this.loadingModalRef = this.modalService.show(this.tipOfTheDayLoadingModal, {
        backdrop: 'static',
        ignoreBackdropClick: true,
        class: 'modal-lg'
      });
    }
  }

  dontShow() {
    this.notificationService.dontShowTipOfTheDayRental(this.tipOfTheDayType).subscribe(response => {
      this.globalService.onSuccess(response['message']);
      this.closeRentalModal();
    }, error => {
      this.globalService.onError('Error while saving data');
    });
  }

  private closeRentalModal(): void {
    if (this.rentalModalRef) {
      this.rentalModalRef.hide();
      this.rentalModalRef = undefined;  // Reset the reference
    }
  }
}
