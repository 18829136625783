import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import {ResponseBodyChangeInterceptorService} from "./interceptors/response-body-change-interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {UserRefResponseBodyInterceptor} from "./interceptors/user-ref-response-body.interceptor";
import { PropertyListViewComponent } from './componants/property/property-list-view/property-list-view.component';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SortablejsModule} from "ngx-sortablejs";
import {NgxSpinnerModule} from "ngx-spinner";
import { PropertyListTileViewComponent } from './componants/property/property-list-tile-view/property-list-tile-view.component';
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {UnauthorisedUserInterceptorService} from "./interceptors/unauthorised-user-interceptor.service ";
import { LearningArticleComponent } from './componants/learning-article/learning-article.component';
import {SimpleNotificationsModule} from "angular2-notifications";
import { PropertyDetailEditViewComponent } from './componants/property/property-detail-edit-view/property-detail-edit-view.component';
import { PropertyDetalComponent } from './componants/property/property-detail/property-detal.component';
import {PropertyPurposeComponent} from "./componants/property/property-purpose/property-purpose.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbDatepickerModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { BuildingMaterialsComponent } from './componants/property/building-materials/building-materials.component';
import { RoofMaterialsComponent } from './componants/property/roof-materials/roof-materials.component';
import {NgxDropzoneModule} from "ngx-dropzone";
import { ViewMoreImagesComponent } from './componants/property/view-more-images/view-more-images.component';
import {LightboxModule} from "ngx-lightbox";
import {ComponentsStateButtonModule} from "../components/state-button/components.state-button.module";
import { AddExpensePaymentModelComponent } from './componants/add-expense-payment-model/add-expense-payment-model.component';
import { ReportIssueComponent } from './componants/report-issue/report-issue.component';
import { PropertySalesExpenseListComponent } from './componants/property/property-sales-expense-list/property-sales-expense-list.component';
import {SafeHtmlPipe} from "./safe-html.pipe";
import {SupplierCreateComponent} from "./componants/supplier/supplier-create/supplier-create.component";
import {PlaceSuggestService} from "./place-suggest.service";
import {ReportIssuesWindowComponent} from "./componants/report-issues-window/report-issues-window.component";
// import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {ExcelService} from "./excel.service";
import { PropertyShareModelComponent } from './componants/property/property-share-model/property-share-model.component';
import { PropertyHiddenModelComponent } from './componants/property/property-hidden-model/property-hidden-model.component';
import {NgxTagsModule} from "ngx-tags-input-box";
import {MortgageFormComponent} from "./componants/Mortgage/mortgage-form/mortgage-form.component";
import {
  MortgageDetailsViewComponent
} from "./componants/Mortgage/mortgage-details-view/mortgage-details-view.component";
import {
  MortgageSplitLoanViewComponent
} from "./componants/Mortgage/mortgage-split-loan-view/motrgage-split-loan-view.component";
import {
  SplitMortgageRowDetailsComponent
} from "./componants/Mortgage/split-mortgage-row-details/split-mortgage-row-details.component";
import {ExpensePaymentViewComponent} from "./componants/expense-payment-view/expense-payment-view.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {StreetNamePipe} from "./street-name.pipe";
import {RentalDetailsViewComponent} from "./componants/rental/rental-details-view/rental-details-view.component";
import { PropertySupplierDetailsViewModelComponent } from './componants/property/property-supplier-details-view-model/property-supplier-details-view-model.component';
import { PreviewComponent } from './componants/preview/preview.component';
import { ThumbComponent } from './componants/thumb/thumb.component';
import { DocumentWindowComponent } from './componants/document-window/document-window.component';
import { PermissionFilterPipe } from './pipes/permission-filter.pipe';
import { SpinnerComponent } from './componants/spinner/spinner.component';
import { TipOfTheDayComponent } from './componants/tip-of-the-day/tip-of-the-day.component';
import {ComponentsCarouselModule} from "../components/carousel/components.carousel.module";

@NgModule({
  declarations: [
    ErrorComponent,
    UnauthorizedComponent,
    PropertyListViewComponent,
    PropertyListTileViewComponent,
    LearningArticleComponent,
    PropertyDetailEditViewComponent,
    PropertyPurposeComponent,
    PropertyDetalComponent,
    BuildingMaterialsComponent,
    RoofMaterialsComponent,
    ViewMoreImagesComponent,
    AddExpensePaymentModelComponent,
    PropertySalesExpenseListComponent,
    AddExpensePaymentModelComponent,
    ReportIssueComponent,
    SafeHtmlPipe,
    ReportIssuesWindowComponent,
    PropertyShareModelComponent,
    PropertyHiddenModelComponent,
    MortgageFormComponent,
    MortgageDetailsViewComponent,
    MortgageSplitLoanViewComponent,
    SplitMortgageRowDetailsComponent,
    ExpensePaymentViewComponent,
    StreetNamePipe,
    RentalDetailsViewComponent,
    PropertySupplierDetailsViewModelComponent,
    PreviewComponent,
    ThumbComponent,
    DocumentWindowComponent,
    PermissionFilterPipe,
    PermissionFilterPipe,
    SpinnerComponent,
    TipOfTheDayComponent
  ],
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        DragDropModule,
        SortablejsModule,
        NgxSpinnerModule,
        SimpleNotificationsModule,
        ModalModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgbModule,
        BsDatepickerModule.forRoot(),
        NgxDropzoneModule,
        LightboxModule,
        ComponentsStateButtonModule,
        NgxTagsModule,
        TooltipModule,
        NgbDatepickerModule,
        GooglePlaceModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        CommonModule,
        ComponentsCarouselModule,
    ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    PropertyListViewComponent,
    PropertyListTileViewComponent,
    LearningArticleComponent,
    PropertyDetalComponent,
    ReportIssueComponent,
    SafeHtmlPipe,
    ReportIssuesWindowComponent,
    PropertyShareModelComponent,
    PropertyHiddenModelComponent,
    MortgageFormComponent,
    MortgageDetailsViewComponent,
    MortgageSplitLoanViewComponent,
    SplitMortgageRowDetailsComponent,
    ExpensePaymentViewComponent,
    StreetNamePipe,
    RentalDetailsViewComponent,
    PropertySupplierDetailsViewModelComponent,
    PreviewComponent,
    ThumbComponent,
    DocumentWindowComponent,
    PermissionFilterPipe,
    SpinnerComponent,
    TipOfTheDayComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ResponseBodyChangeInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UserRefResponseBodyInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorisedUserInterceptorService, multi: true},
    PlaceSuggestService,
    ExcelService
  ],
})
export class SharedModule {}
