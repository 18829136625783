<ng-template #tipOfTheDayModal>
  <div class="modal-header bg-primary text-white">
    <h5 class="modal-title" id="dialog-sizes-name1">
      <img src="assets/img/tipoftheday/github-copilot-seeklogo.svg" alt="GitHub Copilot Logo" />
      Tip of the Day: Proppel AI Insights
    </h5>
    <button #closeButton type="button" class="close text-white" aria-label="Close" (click)="rentalModalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- rental_rates -->
  <div class="modal-body" *ngIf="tipOfTheDayType == 'rental_rates'">
    <p class="font-weight-bold text-secondary">
      Our AI has analyzed recent data to bring you potential high-value rental insights:
    </p>

    <table class="table table-bordered table-hover text-center mt-4">
      <thead class="thead-light">
      <tr>
        <th>Property Address</th>
        <th>Rent (per week)</th>
        <th>Rental Median</th>
        <th>Link</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let property of properties">
        <td class="font-weight-bold">{{ property.property_address }}</td>
        <td class="text-danger font-weight-bold">{{ property.current_rent_pw | currency:'USD':'symbol' }}
          <span class="text-danger">&#9660;</span>
        </td>
        <td class="text-success font-weight-bold">{{ property.rental_median | currency:'USD':'symbol' }}
          <span class="text-success">&#9650;</span>
        </td>
        <td>
          <a [routerLink]="['/app/properties/rental', property.property_ref]"
             target="_blank"
             class="btn btn-outline-primary btn-sm">
            View
          </a>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- end rental_rates -->
  <!-- sold_properties -->
  <div class="modal-body sold-property-over-flow" *ngIf="tipOfTheDayType == 'sold_properties'">
    <p class="font-weight-bold text-secondary">
      Proppel AI has analyzed recent market trends to highlight high-value properties that have been sold in this area, offering you a glimpse into the potential of the local real estate market:
    </p>

    <div *ngFor="let area of properties">
      <h3>{{ area.suburb }}, {{ area.state }} ({{ area.postcode }})</h3>

      <div class="col-md-12 mb-4">

        <!-- Carousel for multiple properties -->
        <app-glide *ngIf="area.properties.length > 1"
                   [settings]="{
        gap: 10,
        type: 'carousel',
        autoplay: 3000,
        hoverpause: true,
        peek: { before: 10, after: 10 },
        perView: 2,
        breakpoints: {
          '600': { perView: 1 },
          '1000': { perView: 2 }
        }
      }">

          <!-- Loop through properties -->
          <div *ngFor="let property of area.properties" class="glide__slide d-flex justify-content-center">
            <div class="card">
              <div class="position-relative">
                <img class="card-img-top img-fluid custom-img-size" [src]="property.image_url" alt="Card image cap">
                <span class="badge badge-pill badge-warning position-absolute badge-top-left">SOLD</span>
              </div>
              <div class="card-body">
                <h6 class="mb-4">
                  {{ property.address }}
                  <strong><i class="fa fa-dollar-sign"></i></strong>
                  {{ property.price | currency:'USD':'symbol' }}
                </h6>
                <footer>
                  <p class="mb-0 text-muted text-small">
                    <strong><i class="fa fa-bed"></i></strong> {{ property.bedrooms }}
                    <strong><i class="fa fa-bath"></i></strong> {{ property.bathrooms }}
                    <strong><i class="fa fa-car"></i></strong> {{ property.parking }}
                  </p>
                  <div class="d-flex justify-content-end">
                    <a *ngIf="property.lat_lng && property.lat_lng !== ''"
                       class="btn btn-primary btn-sm mr-2"
                       [href]="'https://www.google.com/maps/search/?api=1&query=' + property.lat_lng"
                       target="_blank">
                      <i class="fa fa-map-marker"></i>
                    </a>
                    <a class="btn btn-secondary btn-sm" [href]="property.scraping_url" target="_blank">
                      <i class="fa fa-external-link-alt"></i> Visit Property
                    </a>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </app-glide>

        <!-- If only one property, display it without carousel -->
        <div class="d-flex justify-content-center" *ngIf="area.properties.length === 1">
          <div class="card">
            <div class="position-relative">
              <!-- Smaller Image Size -->
              <img class="card-img-top img-fluid custom-img-size" [src]="area.properties[0].image_url" alt="Card image cap">
              <span class="badge badge-pill badge-warning position-absolute badge-top-left">SOLD</span>
            </div>
            <div class="card-body">
              <h6 class="mb-4">{{ area.properties[0].address }} - <strong><i class="fa fa-dollar-sign"></i></strong> {{ area.properties[0].price | currency:'USD':'symbol' }}</h6>
              <footer>
                <p class="mb-0 text-muted text-small">
                  <strong><i class="fa fa-bed"></i></strong> {{ area.properties[0].bedrooms }}
                  <strong><i class="fa fa-bath"></i></strong> {{ area.properties[0].bathrooms }}
                  <strong><i class="fa fa-car"></i></strong> {{ area.properties[0].parking }}
                </p>

                <div class="d-flex justify-content-end">
                  <a *ngIf="area.properties[0].lat_lng && area.properties[0].lat_lng !== ''"
                     class="btn btn-primary btn-sm mr-2"
                     [href]="'https://www.google.com/maps/search/?api=1&query=' + area.properties[0].lat_lng"
                     target="_blank">
                    <i class="fa fa-map-marker"></i>
                  </a>

                  <a class="btn btn-secondary btn-sm" [href]="area.properties[0].scraping_url" target="_blank">
                    <i class="fa fa-external-link-alt"></i> Visit Property
                  </a>
                </div>
              </footer>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>


  <!-- end sold_properties -->
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dontShow();rentalModalRef?.hide()">Dismiss and Don’t Show Again</button>
  </div>

</ng-template>

<ng-template #tipOfTheDayLoadingModal>
  <div class="modal-header bg-primary text-white">
    <h5 class="modal-title animated-title" id="dialog-sizes-name3">Please wait<span class="dots">...</span></h5>
    <button type="button" class="close text-white" aria-label="Close" (click)="loadingModalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body animated-body">
    <p class="font-weight-bold text-secondary">
      Our AI is busy analyzing the latest data to bring you valuable insights. Hang tight, and we’ll have something for you soon!
    </p>

    <!-- Skeleton Loading Elements -->
    <div class="skeleton-line"></div>
    <div class="skeleton-line short"></div>
    <div class="skeleton-line medium"></div>
    <div class="skeleton-line"></div>
    <div class="skeleton-line short"></div>
    <!-- Animated Loading Text -->
    <p class="loading-text">Loading<span class="dots">...</span></p>
  </div>

  <div class="modal-footer"></div>
</ng-template>

